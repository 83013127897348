<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <Callboard
          :selected="selected"
        ></Callboard>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <LeadTable
          v-if="leads.length > 0"
          :leads="leads"
          @selectedLead="selectLead"
        ></LeadTable>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Callboard from './Callboard/Callboard.vue'
import LeadTable from './Callboard/LeadTable.vue'

export default {
  components: {
    Callboard,
    LeadTable,
  },
  data() {
    return {
      leads: [],
      selected: {},
    }
  },
  computed: {
  },
  mounted() {
    this.fetchData()
  },
  methods: {

    fetchData() {
      Promise.all([this.fetchLeads()])
        .then(values => {
          [this.leads] = values
          console.log(values)
        })
        .catch(err => {
          console.log(err)
        })
    },
    async fetchLeads() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/leads`)

      return res.data
    },
    selectLead(payload) {
      this.selected = payload
    },
  },
}
</script>

<style>

</style>
