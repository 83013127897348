<template>
  <v-data-table
    height="500"
    :items="leads"
    :headers="headers"
    @click:row="select"
  >
    <template
      #[`item.name`]="{item}"
    >
      {{ item.firstName }} {{ item.lastName }}
    </template>
    <template
      #[`item.created_at`]="{item}"
    >
      {{ new Date(item.created_at) | date('dd/MM/yyyy') }}
    </template>
    <template
      #[`item.status`]="{item}"
    >
      <v-chip
        :color="statusColor[item.status]"
      >
        {{ statusText[item.status] }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    leads: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headers: [
      {
        text: 'Nom & Prenom',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Tel.',
        align: 'start',
        sortable: true,
        value: 'phone',
      },
      {
        text: 'Status',
        value: 'status',
        align: 'center',
      },
      {
        text: 'Reçu le',
        value: 'created_at',
        align: 'center',
      },
    ],
    statusColor: {
      /* eslint-disable key-spacing */
      rappel: 'primary',
      valid: 'success',
      dead: 'error',
      nrp: 'warning',
      autre: 'info',
      notcontacted: 'info',
      null: 'info',
      /* eslint-enable key-spacing */
    },
    statusText: {
      /* eslint-disable key-spacing */
      rappel: 'A Rappeler',
      dead: 'Pas intéressé',
      nrp: 'Ne Répond pas',
      notcontacted: 'Pas encore contacté',
      valid: 'Validé',
      autre: 'Autre',
      null: 'Pas encore contacté',
      /* eslint-enable key-spacing */
    },
  }),
  methods: {
    select(e) {
      this.$emit('selectedLead', e)
    },
  },
}
</script>

<style>

</style>
