<template>
  <div>
    <v-row>
      <!-- Callboard Table -->
      <v-col
        v-if="selected"
        cols="12"
      >
        <v-card class="overflow-hidden">
          <v-row class="ma-0 h-full">
            <v-col
              cols="12"
              sm="8"
              md="12"
              lg="8"
              order="2"
              order-lg="1"
            >
              <v-card-title>
                {{ selected.lastName }}  {{ selected.firstName }}
              </v-card-title>
              <v-card-text>
                {{ selected.note }}
              </v-card-text>
              <v-card-text>
                <v-divider></v-divider>
              </v-card-text>
              <v-card-actions class="d-flex justify-center">
                <v-row>
                  <v-col
                    md="12"
                  >
                    <div class="me-auto pe-4">
                      <p class="d-flex align-center mb-6">
                        <v-icon color="primary">
                          {{ icons.mdiStarOutline }}
                        </v-icon>
                        <span class="ms-3">
                          <v-chip
                            v-for="(interet, index) in interestsArray"
                            :key="index"
                            color="primary"
                            class="mx-2"
                            outlined
                          >
                            {{ interet }}
                          </v-chip>
                        </span>
                      </p>
                      <p class="d-flex align-center mb-0">
                        <v-icon color="primary">
                          {{ icons.mdiAt }}
                        </v-icon>
                        <span class="ms-3">{{ selected.email }}</span>
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    md="12"
                  >
                    <v-form>
                      <v-text-field
                        v-model="selected.solde_cpf"
                        :prepend-inner-icon="icons.mdiCurrencyEur"
                        label="Solde CPF"
                        outlined
                        lazy
                        dense
                        placeholder="Solde CPF"
                      ></v-text-field>
                      <v-textarea
                        v-model="selected.note"
                        :prepend-inner-icon="icons.mdiClipboardOutline"
                        label="Note"
                        outlined
                        dense
                        placeholder="Note"
                      ></v-textarea>
                      <v-rating
                        v-model="selected.rating"
                        color="warning"
                        background-color="warning"
                        dense
                        @input="rateLead"
                      ></v-rating>
                    </v-form>
                  </v-col>
                  <v-col
                    md="12"
                    class="d-flex"
                  >
                    <v-btn
                      color="warning"
                      class="mt-6"
                      @click="updateLeadStatus('nrp')"
                    >
                      Ne répond pas
                    </v-btn>
                    <v-btn
                      color="error"
                      class="mt-6 ml-4"
                      @click="updateLeadStatus('dead')"
                    >
                      Pas intéressé
                    </v-btn>
                    <v-btn
                      color="info"
                      class="mt-6 ml-4"
                      @click="updateLeadStatus('other')"
                    >
                      Autre
                    </v-btn>
                    <v-btn
                      color="success"
                      class="mt-6 ml-4"
                      @click="updateLeadStatus('valid')"
                    >
                      Validé
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      outlined
                      color="info"
                      dark
                      class="mt-6 ml-4"
                    >
                      Programmer un rappel
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="12"
              lg="4"
              order="1"
              order-lg="2"
              class="memberpricing-bg"
            >
              <div class="membership-pricing">
                <p class="mt-16 text--primary">
                  <sup class="font-weight-semibold">{{ nicePhone(selected.phone) }}</sup>
                </p>
                <p
                  class="my-6 text--secondary text-sm"
                >
                  Lead reçu le : {{ displayDate(selected.created_at) }}
                </p>
                <p
                  v-if="selected.contacted =! null"
                  class="my-6 text--secondary text-sm"
                >
                  Déjà appelé le : {{ selected.contacted }}
                </p>
                <p
                  v-if="selected.status == 'rappel' "
                  class="my-6 text--secondary text-sm"
                >
                  Rappeler le : {{ displayDate(selected.rappelDate) }}
                </p>
                <v-btn
                  :href="`tel:${selected.phone}`"
                  color="primary"
                  class="mt-6"
                >
                  Appeler
                  <v-icon
                    right
                  >
                    {{ icons.mdiPhoneInTalkOutline }}
                  </v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-sheet class="d-flex justify-space-around">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              icon
            >
              <v-icon>
                {{ icons.mdiChevronLeft }}
              </v-icon>
            </v-btn>
            <v-btn
              color="primary"
              icon
            >
              <v-icon>
                {{ icons.mdiChevronRight }}
              </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-sheet>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAt,
  mdiPhoneInTalkOutline,
  mdiCurrencyEur,
  mdiClipboardOutline,
  mdiLockOpenOutline,
  mdiStarOutline,
  mdiAccountOutline,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'

export default {
  props: {
    selected: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    rating: 0,
    note: '',
    solde_cpf: 0,
    now: new Date(),
    time: null,
    modalTime: false,
    dateRappel: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    otherDate: false,
    icons: {
      mdiAt,
      mdiPhoneInTalkOutline,
      mdiCurrencyEur,
      mdiClipboardOutline,
      mdiLockOpenOutline,
      mdiStarOutline,
      mdiAccountOutline,
      mdiChevronLeft,
      mdiChevronRight,
    },
  }),

  computed: {
    niceDate() {
      return 'Demain'
    },
    interestsArray() {
      if (this.selected) {
        return this.selected.interests.match(/[A-Z][a-z]+/g)
      }

      return []
    },
  },
  watch: {
    dateRappel() {
      this.otherDate = false
    },
    time() {
      this.$http.put(`${process.env.VUE_APP_API_URL}/leads/${this.selected.id}`,
        {
          status: 'rappel',
          rappelDate: this.niceDate,
          contacted: this.now,
        }).catch(err => console.log(err))
    },
  },
  methods: {
    nicePhone(phone) {
      return ((phone).replace(/ /g, '').replace('336', '06')).replace(/(.{2})/g, '$1 ')
    },
    rateLead(e) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/leads/${this.selected.id}`, { rating: e }).catch(err => console.log(err))
    },
    updateLeadStatus(status) {
      console.log(status)
    },
    updateSoldeLead(e) {
      this.$http.put(`${process.env.VUE_APP_API_URL}/leads/${this.selected.id}`, { rating: e }).catch(err => console.log(err))
    },
    defineRappelDate(e) {
      switch (e) {
        case 'aujourdhui':
          this.dateRappel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          break
        default:
          this.dateRappel = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
          break
      }
    },
    displayDate(date) {
      return this.$date(new Date(date), 'dd/MM/yyyy à HH:mm')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/plugins/vuetify/default-preset/preset/mixins.scss';

.avatar-center {
  top: -2rem;
  left: 1rem;
  border: 3px solid white;
  position: absolute;
}

// membership pricing
@include theme--child(memberpricing-bg) using ($material) {
  background-color: rgba(map-deep-get($material, 'primary-shade'), map-deep-get($material, 'states', 'hover'));
}

.memberpricing-bg {
  position: relative;
}
.membership-pricing {
  text-align: center;
  sup {
    font-size: 2.75rem;
    top: 9px;
  }
}
</style>
