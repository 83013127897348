<template>
  <div>
    <DashboardTuteur v-if="$store.getters.userStatus ==='tuteur'"></DashboardTuteur>
    <DashboardTelepro v-if="$store.getters.userStatus ==='telepro'"></DashboardTelepro>
  </div>
</template>

<script>
import DashboardTuteur from './DashboardTuteur.vue'
import DashboardTelepro from './DashboardTelepro.vue'

export default {
  components: {
    DashboardTuteur,
    DashboardTelepro,
  },
  data: () => ({

  }),

}
</script>

<style>

</style>
