<template>
  <v-dialog
    v-model="dialog"
    persistent
    @keydown.esc="$emit('closeDialog')"
    @click:outside="$emit('closeDialog')"
  >
    <v-card
      :loading="loading"
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <h4>
          Nouvel e-mail
        </h4>
        <v-icon
          top
          right
        >
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
          ref="form"
        >
          <v-combobox
            v-model="email.to"

            label="Destinataire : "
            :items="students"
            :rules="[v =>!!v || 'Destinataire requis']"
            :item-text="item=>`${item.prenom} ${item.nom} (${item.formation.nom})`"
            chips
            required
          ></v-combobox>
          <v-text-field
            v-model="email.subject"
            :rules="[v =>!!v || 'L\'objet est requis']"
            label="Objet"
          >
          </v-text-field>
          <v-textarea
            v-model="email.content"
            :rules="[v =>!!v || 'L\'e-mail est vide.']"
            rows="15"
            label="Message"
          ></v-textarea>
          <div
            v-show="signature"
            v-html="userSignature"
          ></div>
        </v-form>
      </v-card-text>
      <v-card-actions class="d-flex justify-space-between">
        <v-btn
          color="error"
          outlined
          class="mb-5"
          @click="$emit('closeDialog')"
        >
          Annuler
        </v-btn>
        <v-btn
          color="info"
          outlined
          class="mb-5"
          @click="resetForm"
        >
          Réinitialiser
        </v-btn>
        <v-spacer></v-spacer>
        <v-switch
          v-model="signature"
          class="ma-3"
          label="Signature mail"
        ></v-switch>
        <v-btn
          :disabled="loading"
          color="primary"
          class="mb-5"
          @click="validateAndSend"
        >
          Envoyer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      students: [],
      signature: true,
      userSignature: this.$store.state.user.emailSignature,
      email: {
        to: '',
        subject: '',
      },
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      this.loading = true
      if (this.student) {
        this.email.to = this.student
        this.loading = false
      } else {
        Promise.all([this.fetchStudents()])
          .then(values => {
            [this.students] = values
            this.loading = false
          })
          .catch(err => console.log(err))
          .finally()
      }
    },
    async fetchStudents() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/students`)

      return res.data
    },
    resetForm() {
      this.email.subject = ''
      this.email.content = ''
    },
    validateAndSend() {
      this.loading = true

      if (this.$refs.form.validate()) {
        const emailContentBackspace = this.email.content.replace(/\r?\n/g, '<br />')
        const mailToSend = {
          to: this.email.to.id,
          subject: this.email.subject,
          content: emailContentBackspace,
          type: `E-mail libre : ${this.email.subject}`,
        }
        if (this.signature) {
          mailToSend.content += this.userSignature
        }

        this.$http.post(`${process.env.VUE_APP_API_URL}/email/sendmail`, mailToSend)
          .then(res => {
            if (res.status === 200) {
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'E-mail envoyé avec succès',
                value: true,
              })
              this.$emit('closeDialog')
            }
          })
          .catch(err => {
            if (err.response && err.response.status === 404) {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Pas d\'apprenant avec cet e-mail',
                value: true,
              })
            } else {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Une erreur est survenue pendant l\'envoi du mail',
                value: true,
              })
            }
            this.loading = false
          })
          .finally()
      }
    },
  },
}
</script>

<style>

</style>
