<template>
  <v-card
    class="overflow-hidden mb-5"
  >
    <v-card-title class="d-flex justify-space-between">
      <v-btn
        icon
        @click="show = !show"
      >
        <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
      </v-btn>
      <h4>
        Calendrier
      </h4>
      <v-icon
        top
        right
      >
        {{ icons.mdiCalendarCursor }}
      </v-icon>
    </v-card-title>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-sheet
            tile
            height="54"
            class="d-flex"
          >
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.prev()"
            >
              <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
            </v-btn>
            <v-spacer>
            </v-spacer>
            <span v-if="$refs.calendar">{{ $refs.calendar.title }}</span>
            <v-spacer>
            </v-spacer>
            <v-btn
              icon
              class="ma-2"
              @click="$refs.calendar.next()"
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </v-sheet>
          <v-calendar
            ref="calendar"
            v-model="cal"
            color="primary"
            first-time="08:00"
            interval-count="15"
            interval-width="90"
            type="week"
            locale="fr"
            :events="orderedMeetings"
            :event-ripple="false"
            @click:event="showEvent"
            @mousedown:event="startDrag"
            @mousedown:time="startTime"
            @mousemove:time="mouseMove"
            @mouseup:time="endDrag"
            @mouseleave.native="cancelDrag"
          >
            <template v-slot:event="{ event, timed }">
              <div class="v-event-draggable">
                <span class="v-event-summary">
                  <strong>{{ event.name }}</strong>
                  <br>{{ new Date(event.start) | date('HH:mm') }} - {{ new Date(event.end) | date('HH:mm') }}</span>
              </div>
              <div
                v-if="timed"
                class="v-event-drag-bottom"
                @mousedown.stop="extendBottom(event)"
              ></div>
            </template>
          </v-calendar>
          <v-menu
            v-model="selectedOpen"
            :close-on-content-click="false"
            :activator="selectedElement"
            offset-x
          >
            <tutorat-planning-meeting-card-time-off
              v-if="selectedEvent.type === 'timeoff'"
              :meeting="selectedEvent"
              @closeOpen="selectedOpen = false"
              @meetingDeleted="meetingDeleted"
              @editMeetingDialogOpen="editMeetingDialogOpen"
            >
            </tutorat-planning-meeting-card-time-off>

            <TutoratPlanningMeetingCardVisio
              v-else
              :meeting="selectedEvent"
              @closeOpen="selectedOpen = false"
              @meetingDeleted="meetingDeleted"
              @editMeetingDialogOpen="editMeetingDialogOpen"
            >
            </TutoratPlanningMeetingCardVisio>
          </v-menu>
          <v-dialog
            v-if="orderedMeetings.length > 0"
            v-model="newEventDialog"
            persistent
            width="600"
            @click:outside="cancelEvent"
          >
            <v-card>
              <v-card-title>Nouvel évènement</v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="sameDay([...orderedMeetings].pop())">
                le {{ new Date([...orderedMeetings].pop().start) | date('dd MMMM') }} de {{ new Date([...orderedMeetings].pop().start) | date('HH:mm') }} à {{ new Date([...orderedMeetings].pop().end) | date('HH:mm') }}
              </v-card-text>
              <v-card-text v-else>
                Du {{ new Date([...orderedMeetings].pop().start) | date('dd MMMM') }} à {{ new Date([...orderedMeetings].pop().start) | date('HH:mm') }} <br>
                Au {{ new Date([...orderedMeetings].pop().end) | date('dd MMMM') }} à {{ new Date([...orderedMeetings].pop().end) | date('HH:mm') }}
              </v-card-text>
              <v-card-text>
                Choisir le type d'évènement :
                <v-btn-toggle
                  v-model="newEventType"
                  tile
                  color="primary"
                  group
                >
                  <v-btn value="visio">
                    Visio
                  </v-btn>

                  <v-btn value="timeoff">
                    Time Off
                  </v-btn>
                </v-btn-toggle>
              </v-card-text>
              <v-expand-transition>
                <v-card-text>
                  <div v-show="newEventType === 'timeoff'">
                    <v-text-field
                      v-model="newEventName"
                      dense
                      outlined
                      placeholder="Nom de l'évènement"
                    >
                    </v-text-field>
                  </div>
                </v-card-text>
              </v-expand-transition>
              <v-expand-transition>
                <v-card-text>
                  <div v-show="newEventType === 'visio'">
                    <v-autocomplete
                      v-model="newEventStudent"
                      dense
                      clearable
                      outlined
                      placeholder="Saisir le nom de l'apprenant"
                      :items="studentListItems"
                      :item-text="item=>`${item.prenom} ${item.nom} - ${item.formation.nom}`"
                      item-value="id"
                      :loading="studentListLoading"
                      hide-no-data
                      hide-selected
                      return-object
                      @change="generateName"
                    >
                    </v-autocomplete>
                  </div>
                  <div v-if="newEventStudent">
                    <v-text-field
                      v-model="newEventName"
                      :rules="[v => !!v || 'Le nom est requis']"
                      dense
                      outlined
                      label="Nom de la réunion"
                    >
                    </v-text-field>
                    <v-text-field
                      v-model="$store.state.user.displayName"
                      dense
                      disabled
                      outlined
                      label="Tuteur"
                    >
                    </v-text-field>
                    <v-textarea
                      v-model="newEventNote"
                      outlined
                      dense
                      label="Note"
                    ></v-textarea>
                  </div>
                </v-card-text>
              </v-expand-transition>

              <v-card-actions class="d-flex justify-space-between">
                <v-btn
                  outlined
                  color="error"
                  @click="cancelEvent"
                >
                  Annuler
                </v-btn>
                <v-btn
                  outlined
                  color="primary"
                  @click="newEvent"
                >
                  Enregistrer
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card-text>
      </div>
    </v-expand-transition>

    <edit-meeting-dialog
      :edit-meeting-dialog="editMeetingDialog"
      :meeting-id="meetingToChangeId"
      :student-id="meetingToChangeStudentId"
      @closeEditDialog="editMeetingDialog = false"
      @meetingUpdated="meetingUpdated"
    ></edit-meeting-dialog>
  </v-card>
</template>

<script>
import {
  mdiCalendar, mdiChevronLeft, mdiChevronRight, mdiChevronUp, mdiChevronDown, mdiCalendarCursor,
} from '@mdi/js'
import TutoratPlanningMeetingCardVisio from '@/views/pages/Tutorat/TutoratPlanningMeetingCardVisio.vue'
import TutoratPlanningMeetingCardTimeOff from '@/views/pages/Tutorat/TutoratPlanningMeetingCardTimeOff.vue'
import EditMeetingDialog from '@/components/EditMeetingDialog.vue'

export default {
  components: {
    TutoratPlanningMeetingCardVisio,
    TutoratPlanningMeetingCardTimeOff,
    EditMeetingDialog,
  },
  props: {
    meetings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      cal: '',
      orderedMeetings: [],
      newMeeting: [],
      newEventDialog: false,
      newEventType: '',
      newEventName: '',
      newEventStudent: '',
      newEventNote: '',
      studentListLoading: false,
      studentListItems: [],
      studentsItems: [],
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      editMeetingDialog: false,
      meetingToChange: {},
      meetingToChangeId: 0,
      meetingToChangeStudentId: 0,
      icons: {
        mdiCalendar,
        mdiChevronLeft,
        mdiChevronRight,
        mdiChevronUp,
        mdiChevronDown,
        mdiCalendarCursor,
      },
      dragEvent: null,
      dragStart: null,
      createEvent: null,
      createStart: null,
      extendOriginal: null,
    }
  },
  computed: {

  },
  watch: {
    meetings(val) {
      if (val) {
        this.orderMeetings()
      }
    },
    newEventType(val) {
      if (val === 'visio' && this.studentListItems.length === 0) {
        this.fetchStudents()
      }
    },
  },
  mounted() {
  },
  methods: {
    fetchStudents() {
      this.studentListLoading = true

      // Lazily load input items
      this.$http.get(`${process.env.VUE_APP_API_URL}/students`)
        .then(res => {
          this.studentListItems = res.data

          // console.log(res)
        })
        .catch(err => console.log(err))
        .finally(() => { this.studentListLoading = false })
    },
    orderMeetings() {
      const meetings = []
      this.meetings.forEach(meet => {
        if (meet.tag === 'newEvent') {
          meetings.push(meet)
        } else {
          meetings.push({
            id: meet.id,
            name: meet.nom,
            color: meet.type === 'timeoff' ? 'secondary' : 'primary',
            start: new Date(meet.start),
            end: new Date(meet.end),
            timed: true,
            type: meet.type,
            students: meet.students,
            users: meet.users,
          })
        }
      })
      this.orderedMeetings = meetings

      return meetings
    },
    startDrag({ event, timed }) {
      if (event && timed) {
        this.dragEvent = event
        this.dragTime = null
        this.extendOriginal = null
      }
    },
    startTime(tms) {
      if (this.menu) { this.cancelEvent() }
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime === null) {
        const { start } = this.dragEvent

        this.dragTime = mouse - start
      } else {
        this.createStart = this.roundTime(mouse)
        this.createEvent = {
          name: `Event #${this.orderedMeetings.length}`,
          color: '#56ca0099',
          start: new Date(this.createStart),
          end: new Date(this.createStart),
          timed: true,
          tag: 'newEvent',
        }

        this.orderedMeetings.push(this.createEvent)
      }
    },
    extendBottom(event) {
      this.createEvent = event
      this.createEvent.color = '#ae89f1'
      this.createStart = event.start
      this.extendOriginal = event.end
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms)

      if (this.dragEvent && this.dragTime !== null) {
        const { start } = this.dragEvent
        const { end } = this.dragEvent
        const duration = end - start
        const newStartTime = mouse - this.dragTime
        const newStart = this.roundTime(newStartTime)
        const newEnd = newStart + duration

        this.dragEvent.start = newStart
        this.dragEvent.end = newEnd
        this.dragEvent.color = '#ae89f1'
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false)
        const min = Math.min(mouseRounded, this.createStart)
        const max = Math.max(mouseRounded, this.createStart)

        this.createEvent.start = min
        this.createEvent.end = max
      }
    },
    endDrag() {
      if (this.dragEvent !== null && this.dragEvent.tag !== 'newEvent') {
        this.updateMeeting(this.dragEvent)
      } else if (this.createEvent !== null && this.createEvent.tag !== 'newEvent') {
        const oldMeeting = this.meetings.find(x => x.id === this.createEvent.id)
        if (new Date(this.createEvent.end).getTime() !== new Date(oldMeeting.end).getTime()) {
          this.updateMeeting(this.createEvent)
        } else {
          this.createEvent.color = 'primary'
        }
      } else {
        this.newEventDialog = true
      }
      this.dragTime = null
      this.dragEvent = null
      this.createEvent = null
      this.createStart = null
      this.extendOriginal = null
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal
        } else {
          const i = this.meetings.indexOf(this.createEvent)
          if (i !== -1) {
            this.events.splice(i, 1)
          }
        }
      }

      this.createEvent = null
      this.createStart = null
      this.dragTime = null
      this.dragEvent = null
    },
    roundTime(time, down = true) {
      const roundTo = 15 // minutes
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? (time) - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime(tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    updateMeeting(event) {
      const foundMatchingOriginal = this.meetings.find(x => x.id === event.id)
      const start = new Date(foundMatchingOriginal.start)
      const end = new Date(foundMatchingOriginal.end)
      const newStart = new Date(event.start)
      const newEnd = new Date(event.end)

      if (start.getTime() === newStart.getTime()
      && end.getTime() === newEnd.getTime()
      && start.getDate() === newStart.getDate()
      && end.getDate() === newEnd.getDate()
      ) {
        return false
      }
      this.$http.put(`${process.env.VUE_APP_API_URL}/meetings/${event.id}`,
        {
          start: newStart,
          end: newEnd,
        })
        .then(res => {
          this.$emit('updateMeeting', res.data)
        })
        .catch(err => console.log(err))
        .finally()

      return true
    },
    setupEvent() {
      this.newEventDialog = false
    },
    setupTimeOff() {
      this.newEventType = 'timeoff'
    },
    cancelEvent() {
      this.newEventDialog = false
      this.orderedMeetings.pop()
    },
    sameDay(event) {
      const start = new Date(event.start)
      const end = new Date(event.end)

      return start.getDate() === end.getDate()
      && start.getMonth() === end.getMonth()
      && start.getFullYear() === end.getFullYear()
    },
    newEvent() {
      const event = this.orderedMeetings.pop()
      let eventToCreate = {}
      if (event.type === 'timeoff') {
        eventToCreate = {
          start: new Date(event.start),
          end: new Date(event.end),
          nom: this.newEventName,
          type: this.newEventType,
          users: this.$store.state.user.id,
        }
      } else {
        eventToCreate = {
          start: new Date(event.start),
          end: new Date(event.end),
          nom: this.newEventName,
          type: this.newEventType,
          users: this.$store.state.user.id,
          note: this.newEventNote,
          students: this.newEventStudent.id,
        }
      }
      this.$http.post(`${process.env.VUE_APP_API_URL}/meetings`, eventToCreate)
        .then(res => {
          this.$emit('newEvent', res.data)
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Nouvel évènement ajouté',
            value: true,
          })
          this.newEventDialog = false
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création de l\'évènement',
            value: true,
          })
          console.log(err)
        })
        .finally()
    },
    generateName() {
      if (!this.newEventStudent) return
      this.$http.get(`${process.env.VUE_APP_API_URL}/meetings/count?students=${this.newEventStudent.id}`)
        .then(res => {
          if (res.data === 0) {
            this.newEventName = `${this.newEventStudent.prenom} ${this.newEventStudent.nom} - ${this.newEventStudent.formation.nom} : Premier visio`
          } else {
            this.newEventName = `${this.newEventStudent.prenom} ${this.newEventStudent.nom} - ${this.newEventStudent.formation.nom} : Visio ${res.data + 1}`
          }
        })
        .catch(err => console.log(err))
        .finally()
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => { this.selectedOpen = true }))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    editMeetingDialogOpen(payload) {
      this.selectedOpen = false
      this.meetingToChange = this.meetings.find(x => x.id === payload)
      this.meetingToChangeId = this.meetingToChange.id
      this.meetingToChangeStudentId = this.meetingToChange.students[0].id
      this.editMeetingDialog = true
    },
    meetingDeleted(payload) {
      this.$emit('meetingDeleted', payload)
    },
    meetingUpdated(payload) {
      this.$emit('updateMeeting', payload)
    },
  },

}
</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>
