<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <v-btn
          icon
          @click="show = !show"
        >
          <v-icon>{{ show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
        </v-btn>
        <h4>
          Apprenants
        </h4>
        <v-icon
          top
          right
        >
          {{ icons.mdiAccountAlert }}
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-expand-transition v-if="tutors.length > 0">
        <v-card-text>
          <div v-show="show">
            <dashboard-tuteur-card-tutors-datatable
              :meetings-given="meetings"
              :tutors="tutors"
              @newEvent="newEvent"
            ></dashboard-tuteur-card-tutors-datatable>
          </div>
        </v-card-text>
      </v-expand-transition>
      <v-card-text v-else>
        Pas d'apprenants affectés
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

import { mdiChevronDown, mdiChevronUp, mdiAccountAlert } from '@mdi/js'
import DashboardTuteurCardTutorsDatatable from './DashboardTuteurCardTutorsDatatable.vue'

export default {
  components: {
    DashboardTuteurCardTutorsDatatable,
  },
  props: {
    meetings: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      show: true,
      tutors: this.$store.getters.myTutors,
      icons: {
        mdiChevronDown,
        mdiChevronUp,
        mdiAccountAlert,
      },
    }
  },
  methods: {
    newEvent(payload) {
      this.$emit('newEvent', payload)
    },
  },
}
</script>

<style>

</style>
