<template>
  <div>
    <v-row>
      <v-col
        v-if="nextMeeting"
        cols="12"
        md="4"
      >
        <dashboard-tuteur-cards-next-meeting
          :next-meeting="nextMeeting"
        >
        </dashboard-tuteur-cards-next-meeting>
      </v-col>
      <v-col
        v-if="nextTimeOff"
        cols="12"
        md="4"
      >
        <dashboard-tuteur-cards-next-time-off
          :next-time-off="nextTimeOff"
        >
        </dashboard-tuteur-cards-next-time-off>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <dashboard-tuteur-cards-calendar
          :meetings="meetings"
          @updateMeeting="meetingUpdated"
          @meetingDeleted="meetingDeleted"
          @newEvent="newEvent"
        >
        </dashboard-tuteur-cards-calendar>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="12"
      >
        <dashboard-tuteur-card-tutors
          :meetings="meetings"
          @newEvent="newEvent"
        ></dashboard-tuteur-card-tutors>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DashboardTuteurCardsNextMeeting from './DashboardTuteurCardsNextMeeting.vue'
import DashboardTuteurCardsNextTimeOff from './DashboardTuteurCardNextTimeOff.vue'
import DashboardTuteurCardsCalendar from './DashboardTuteurCardCalendar.vue'
import DashboardTuteurCardTutors from './DashboardTuteurCardTutors.vue'

export default {
  components: {
    DashboardTuteurCardsNextMeeting,
    DashboardTuteurCardsNextTimeOff,
    DashboardTuteurCardsCalendar,
    DashboardTuteurCardTutors,
  },
  data() {
    return {
      meetings: [],
    }
  },
  computed: {
    futureEvents() {
      if (this.meetings.length > 0) {
        const now = Date.now()
        const futureEvents = this.meetings.filter(x => new Date(x.start).getTime() > now)
        if (futureEvents.length > 0) {
          const sortedMeetings = futureEvents.sort((a, b) => {
            const timea = Math.abs(now - new Date(a.start).getTime())
            const timeb = Math.abs(now - new Date(b.start).getTime())

            return timea - timeb
          })

          return sortedMeetings
        }
      }

      return []
    },
    nextMeeting() {
      if (this.futureEvents.length > 0) {
        const futureMeetings = this.futureEvents.filter(x => x.type !== 'timeoff')
        const [nextMeeting] = futureMeetings

        return nextMeeting
      }

      return {}
    },
    nextTimeOff() {
      if (this.futureEvents.length > 0) {
        const futureTimeOffs = this.futureEvents.filter(x => x.type === 'timeoff')
        const [nextTimeOff] = futureTimeOffs

        return nextTimeOff
      }

      return {}
    },
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      Promise.all([this.fetchMeetings()])
        .then(values => {
          [this.meetings] = values
        })
        .catch(err => console.log(err))
    },
    async fetchMeetings() {
      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/meetings?users=${this.$store.state.user.id}`)

      return res.data
    },
    meetingUpdated(payload) {
      const matchingIndex = this.meetings.findIndex(x => x.id === payload.id)
      this.meetings.splice(matchingIndex, 1, payload)
    },
    newEvent(payload) {
      this.meetings.push(payload)
    },
    meetingDeleted(payload) {
      this.meetings = this.meetings.filter(x => x.id !== payload)
    },
  },
}
</script>

<style>

</style>
