<template>
  <v-card
    color="grey lighten-4"
    min-width="350px"
    flat
  >
    <v-toolbar
      :color="event.color"
    >
      <v-toolbar-title>
        <p class="white--text">
          {{ event.name }}
        </p>
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-card-text>
      <v-row class="mt-3">
        <v-col
          cols="1"
          align="center"
        >
          <v-icon>{{ icons.mdiCalendarClock }}</v-icon>
        </v-col>
        <v-col cols="11">
          {{ new Date(event.start) | date('EEEE,dd MMMM') }} - {{ new Date(event.start) | date('HH:mm') }} à {{ new Date(event.end) | date('HH:mm') }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="event.contacts">
      <v-row
        v-for="(contact, _id) in event.contacts"
        :key="_id"
        align="center"
      >
        <v-col
          cols="1"
          align="center"
        >
          <v-icon>{{ icons.mdiAccount }}</v-icon>
        </v-col>
        <v-col
          cols="11"
        >
          <PersonChip
            :person="contact.contact"
            type="contact"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col
          cols="1"
          align="center"
        >
          <v-icon>{{ icons.mdiSchool }}</v-icon>
        </v-col>
        <v-col cols="11">
          NOM DE LA FORMATION
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col
          cols="1"
          align="center"
        >
          <v-icon>{{ icons.mdiBriefcase }}</v-icon>
        </v-col>
        <v-col cols="11">
          NOM DU TUTEUR
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text v-if="event.note">
      <v-row>
        <v-col
          cols="1"
          align="center"
        >
          <v-icon>{{ icons.mdiClipboardText }}</v-icon>
        </v-col>
        <v-col cols="11">
          <span v-html="event.description"></span>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        outlined
        color="secondary"
        @click="$emit('closeOpen')"
      >
        Fermer
      </v-btn>
      <v-spacer></v-spacer>

      <v-btn
        icon
        @click="$emit('editeventDialogOpen', event._id)"
      >
        <v-icon>{{ icons.mdiPencil }}</v-icon>
      </v-btn>

      <v-btn
        icon
        @click="deleteevent(event._id)"
      >
        <v-icon>{{ icons.mdiTrashCan }}</v-icon>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {
  mdiCalendarClock, mdiAccount, mdiBriefcase, mdiSchool, mdiClipboardText, mdiPencil, mdiTrashCan,
} from '@mdi/js'

import PersonChip from '@/components/PersonChip.vue'

export default {
  components: {
    PersonChip,
  },
  props: {
    event: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiCalendarClock,
        mdiAccount,
        mdiBriefcase,
        mdiSchool,
        mdiClipboardText,
        mdiPencil,
        mdiTrashCan,
      },
    }
  },
  methods: {
    deleteEvent(id) {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/event/${id}`)
        .then(res => {
          if (res.status === 200) {
            this.$store.commit('snackMe', {
              color: 'success',
              text: 'RDV supprimé avec succès',
              value: true,
            })
            this.$emit('closeOpen')
            this.$emit('meetingDeleted', res.data._id)
          }
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression du RDV.',
            value: true,
          })
        })
    },
  },

}
</script>

<style>

</style>
