<template>
  <div>
    <v-chip
      :close="deletable"
      :close-icon="icons.mdiDelete"
      :color="type==='contact' ? 'info' : 'primary'"
      @click:close="$emit('deleted', person._id)"
    >
      <v-avatar v-if="type ==='contact'">
        <v-icon>{{ icons.mdiAccount }}</v-icon>
      </v-avatar>
      <v-avatar v-else-if="type ==='bc'">
        <v-icon>{{ icons.mdiBriefcase }}</v-icon>
      </v-avatar>
      <user-avatar
        v-else
        :user="person"
      ></user-avatar>
      {{ getName() }}
    </v-chip>
  </div>
</template>

<script>
import { mdiAccount, mdiDelete, mdiBriefcase } from '@mdi/js'
import UserAvatar from '@/components/UserAvatar.vue'

export default {
  components: {
    UserAvatar,
  },
  props: {
    person: {
      type: Object,
      default: () => {},
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'contact',
    },
  },
  data: () => ({
    icons: {
      mdiAccount,
      mdiDelete,
      mdiBriefcase,
    },
  }),
  methods: {
    getName() {
      switch (this.type) {
        case 'user':
          return this.person.displayName
        case 'contact':
          return `${this.person.firstName} ${this.person.lastName}`
        case 'bc':
          return this.person.name

        default:
          return this.person.displayName
      }
    },
  },

}
</script>

<style>

</style>
