<template>
  <div>
    <v-skeleton-loader
      v-if="loading"
      type="table"
    ></v-skeleton-loader>
    <v-data-table
      v-else
      :headers="headers"
      :items="tutors"
      item-key="id"
      class="elevation-1"
      @click:row="selectRow"
    >
      <template
        v-if="Object.keys(selected).length > 0"
        #top
      >
        <dashboard-tuteur-card-tutors-datatable-selected-card
          :student="selected"
          :meetings="getMeetingsForStudent(selected.id)"
          @newEvent="newEvent"
        >
        </dashboard-tuteur-card-tutors-datatable-selected-card>
      </template>
      <template
        #[`item.nom`]="{item}"
      >
        {{ item.prenom }} {{ item.nom }}
      </template>
      <template
        v-if="formations.length > 0"
        #[`item.formation`]="{item}"
      >
        {{ getFormationName(item.formation) }}
      </template>
      <template
        v-if="formations.length > 0"
        #[`item.hvisio`]="{item}"
      >
        <div v-if="getFormationVisio(item.formation)">
          {{ getFormationVisio(item.formation) }} heures
        </div>
        <div v-else>
          <a @click="$router.push({name:'formation-edit', params: {id: item.formation} })">
            Pas d'heure de visio configuré
          </a>
        </div>
      </template>
      <template
        #[`item.nextVisio`]="{item}"
      >
        <div
          v-if="nextVisio(item.id)"
        >
          {{ new Date(nextVisio(item.id).start) | date('dd MMMM à HH:mm') }}
        </div>
        <div
          v-else
        >
          Pas de visio prévu
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import DashboardTuteurCardTutorsDatatableSelectedCard from './DashboardTuteurCardTutorsDatatableSelectedCard.vue'

export default {
  components: {
    DashboardTuteurCardTutorsDatatableSelectedCard,
  },
  props: {
    tutors: {
      type: Array,
      default: () => [],
    },
    meetingsGiven: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: true,
      formations: [],
      meetings: [],
      selected: {},
      headers: [
        {
          text: 'Nom',
          align: 'start',
          sortable: true,
          value: 'nom',
        },
        {
          text: 'Formation',
          align: 'center',
          sortable: true,
          value: 'formation',
        },
        {
          text: 'Heures de visio',
          align: 'center',
          sortable: true,
          value: 'hvisio',
        },
        {
          text: 'Prochain visio',
          align: 'center',
          sortable: true,
          value: 'nextVisio',
        },
      ],
    }
  },
  watch: {
    tutors(val) {
      if (val) {
        this.fetchData(val)
      }
    },
    meetingsGiven() {
      this.meetingUpdated()
    },
  },
  mounted() {
    this.fetchData(this.tutors)
  },
  methods: {
    fetchData(val) {
      Promise.all([this.fetchFormations(val), this.fetchMeetings(val)])
        .then(values => {
          [this.formations, this.meetings] = values
          this.loading = false
        })
    },
    async fetchFormations(tutors) {
      const formationsIds = tutors.map(x => x.formation)
      const formationsIdsUnique = [...new Set(formationsIds)]

      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/formations/multiple/${formationsIdsUnique.toString()}`)

      return res.data
    },
    async fetchMeetings(tutors) {
      const studentsId = tutors.map(x => x.id)
      const studentsIdUniques = [...new Set(studentsId)]

      const res = await this.$http.get(`${process.env.VUE_APP_API_URL}/meetings/multiple/${studentsIdUniques}`)

      return res.data
    },
    selectRow(item) {
      this.selected = { ...item }
      this.selected.formation = this.formations.find(x => x.id === item.formation)
    },
    getFormationName(id) {
      const matchingFormation = this.formations.find(x => x.id === id)
      if (matchingFormation) {
        return matchingFormation.nom
      }

      return ''
    },
    getFormationVisio(id) {
      const matchingFormation = this.formations.find(x => x.id === id)
      if (matchingFormation) {
        return matchingFormation.visio
      }

      return 0
    },
    getMeetingsForStudent(id) {
      return this.meetings.filter(x => x.students.some(student => student.id === id))
    },
    nextVisio(id) {
      const studentMeetings = this.meetings.filter(x => x.students[0].id === id)
      const now = Date.now()
      const futurMeetings = studentMeetings.filter(x => new Date(x.start).getTime() > now)
      if (futurMeetings) {
        const [singleMeeting] = futurMeetings

        return singleMeeting
      }

      return false
    },
    async meetingUpdated() {
      this.meetings = await this.fetchMeetings(this.tutors)
    },
    newEvent(payload) {
      this.meetings.push(payload)
      this.$emit('newEvent', payload)
    },
  },
}
</script>

<style>

</style>
