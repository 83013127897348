<template>
  <div>
    <v-card
      class="overflow-hidden mb-5"
    >
      <v-card-title class="d-flex justify-space-between">
        <v-btn
          class="mx-2"
          fab
          dark
          small
          color="primary"
        >
          <v-icon>
            {{ icons.mdiCalendarClock }}
          </v-icon>
        </v-btn>

        <v-icon>
          {{ icons.mdiDotsVertical }}
        </v-icon>
      </v-card-title>

      <v-card-text v-if="Object.keys(nextMeeting).length > 0">
        <p class="subtitle-2">
          Prochain RDV :
        </p>
        <h2>
          {{ dayMeeting }} de {{ new Date(nextMeeting.start) | date('HH:mm') }} à  {{ new Date(nextMeeting.end) | date('HH:mm') }} ({{ nextMeeting.duration / 60 }}h)
        </h2>
        <h3 class="mt-3">
          {{ nextMeeting.nom }}
        </h3>
      </v-card-text>
      <v-card-text v-else>
        <p>
          Pas de meeting à venir
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mdiCalendarClock, mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    nextMeeting: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      icons: {
        mdiCalendarClock,
        mdiDotsVertical,
      },
    }
  },
  computed: {
    dayMeeting() {
      if (Object.keys(this.nextMeeting).length > 0) {
        const today = new Date()
        const date = new Date()
        const tomorrow = new Date(date.setDate(date.getDate() + 1))
        const startDate = new Date(this.nextMeeting.start)
        const isToday = today.getDate() === startDate.getDate()
            && today.getMonth() === startDate.getMonth()
            && today.getFullYear() === startDate.getFullYear()

        const isTomorrow = tomorrow.getDate() === startDate.getDate()
            && tomorrow.getMonth() === startDate.getMonth()
            && tomorrow.getFullYear() === startDate.getFullYear()

        if (isToday) {
          return 'Aujourd\'hui'
        } if (isTomorrow) {
          return 'Demain'
        }

        return `Le ${this.$date(startDate, 'dd MMMM')}`
      }

      return ''
    },
  },

}
</script>

<style>

</style>
